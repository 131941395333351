import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Carousel from 'react-bootstrap/Carousel';
import Media from './Media';

const HeroSection = (props) => {
    const { children, content } = props;
    const [textColour, setTextColour] = useState(typeof content[0]?.textColor !== "undefined" ? content[0]?.textColor : "light");
    useEffect(() => {
        if (content.length) {
            setTextColour(content[0]?.textColor);
        }
    }, [content]);
    return (
        <div className={classnames('hero', props.className, {"outdent": props.outdent, "fullheight": props.fullheight, "gradient-overlay": props.gradient})}>
            {children && <div className={`overlay aui-color-text-light`}>
                <div className={classnames({ "px-5 px-small-7": props.outdent, "container-fluid": !props.outdent })}>
                    <div className="row">
                        <div className={classnames({"col-medium-1": props.outdent, "col-1": !props.outdent})} />
                        <div className="col-10 col-medium-6">
                            {children}
                        </div>
                    </div>
                </div>
            </div>}
            {content.length === 1 && <Media {...content[0]} imgWide={props.imgWide} />}
            {content.length > 1 && <Carousel interval={props.interval} onSelect={idx => { setTextColour(content[idx].textColor); }}>
                {content.map(slide => {
                    return (
                        <Carousel.Item key={slide.id}>
                            <Media {...slide} type={slide.type || props.defaultType} imgWide={props.imgWide} />
                        </Carousel.Item>
                    );
                })}
            </Carousel>}
        </div>
    );
}

HeroSection.propTypes = {
    outdent: PropTypes.bool,
    imgWide: PropTypes.bool,
    className: PropTypes.string,
    content: PropTypes.array,
    interval: PropTypes.number,
    children: PropTypes.node
};

HeroSection.defaultProps = {
    interval: 10000
};

export default HeroSection;
