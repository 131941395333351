import React from 'react';
import { Formik, Form } from 'formik';
import { validate, defaultSchema } from '../../../../lib/validation';
import Button from '../../../../audi-ui-components/Button';
import FormField from '../../../../components/FormField';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import { request } from '../../../../lib/apiRequestWrapper';
import { gtmPush } from '../../../../lib/gtm';
import { FIELD_RADIOS } from '../../../../constants';

import { connect } from 'react-redux';
import { addWaitlist } from '../../../../redux/Experience';
const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        addWaitlist: (w) => dispatch(addWaitlist(w))
    };
};

class FormWaitlist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            serverError: null
        }
    }

    submitForm = (values, formikBag) => {
        values.eventId = this.props.eventId;
        values.sessionId = this.props.session.id;
        values.firstName = this.props.profile.firstName;
        values.lastName = this.props.profile.lastName;
        values.email = this.props.profile.email;
        request(
          `${process.env.RAZZLE_API}/1/experience/waitlists`,
          {
            method: 'POST',
            body: JSON.stringify(values),
          }
      ).then((res) => {
          // success
          this.props.addWaitlist(res);
          gtmPush("AudiExperience", "eventJoinWaitlistSubmit", this.props.eventName);
        }).catch((error) => {
            let msg = "An error has occured";
            if (error.body && error.body.modelState) {
              formikBag.setErrors(error.body.modelState);
              msg = false;
            } else if (error.body && error.body.message) {
              msg = error.body.message;
            }
            this.setState({serverError: msg});
            formikBag.setSubmitting(false);
        });
    }

    validateForm = (values) => {
        var schema = {
            phone: defaultSchema['mobile']
        };
        return validate(values, schema, {format: "firstError", fullMessages: false});
    }

    render() {
        const {profile, session, allowsGuest, isGuestFree, isDoubleBooking} = this.props;
        return (
            <div className="position-relative">
                <h2 className="aui-headline-3 mt-4 mb-3"><b>Waitlist</b></h2>

                <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={{phone: this.props.profile.mobile || ""}}>
                    {(formikBag) => (
                        <Form>

                            {formikBag.isSubmitting && <LoadingOverlay bgColour="gray95" />}

                            <p className="mb-7">The allocation for this Audi Experience event is exhausted. You can register for our wait list below and, if a ticket becomes available, we’ll notify you via SMS.</p>


                            <FormField name="phone" label="Mobile phone" formikBag={formikBag} />

                            {((session.waitlistSpotsAvailable > 1 || isDoubleBooking) && allowsGuest) && <>
                                <FormField
                                    name="hasGuest"
                                    label="Would you like to bring a guest?"
                                    fieldType={FIELD_RADIOS}
                                    options={[
                                        { label: 'Yes', value: 'true' },
                                        { label: 'No', value: 'false' },
                                    ]}
                                    formikBag={formikBag}
                                />
                                {!isDoubleBooking && <p className="aui-color-text-red mb-4">Please note, you will not be able to add a guest later.</p>}
                            </>}

                            {this.state.serverError && <p className="aui-color-text-red py-3">{this.state.serverError}</p>}
                            <Button label="Submit" buttonType="primary" type="submit" />
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormWaitlist);
