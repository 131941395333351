import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import isMobile from '../lib/isMobile'

const Media = ({
  type,
  useFullImg,
  imgWide,
  children,
  className,
  url,
  altText,
  textColor,
  x,
  y,
  id,
  noControls,
  useEmbedWrapper
}) => {
  if (type.toLowerCase() === 'image') {
    return (
      <figure className={`media-image ${className || ''}`}>
        {useFullImg && (
          <img src={url} alt={altText} style={{ width: '100%' }} />
        )}
        {!useFullImg && (
          <div
            className={`img ${imgWide && 'wide'} aui-color-text-${textColor}`}
            style={{
              backgroundImage: `url(${url})`,
              backgroundPosition: `${x} ${y}`,
            }}
            role="img"
            aria-label={altText}>
            {children}
          </div>
        )}
      </figure>
    );
  }
  if (type.toLowerCase() === 'mp4') {
    const vid = useRef(null);
    const [useControls, setUseControls] = useState(false);
    useEffect(() => {
      if (isMobile()) {
        setUseControls(true);
      }
      let promise = vid.current.play();
      if (promise !== undefined) {
          promise.then(() => {
            // autoplay started
          }).catch(() => {
            setUseControls(true);
          });
      }
    }, [url]);
    if (useEmbedWrapper) {
      return (
        <div className="media-embed__background">
          <div className="media-embed__width">
            <div className="media-embed__height">
              <video ref={vid} controls={useControls && !noControls} muted playsInline loop className="media-mp4">
                <source src={url} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      );
    }
    return (
      <video ref={vid} controls={useControls && !noControls} muted playsInline loop className="media-mp4">
        <source src={url} type="video/mp4" />
      </video>
    );
  }
  if (type.toLowerCase() === 'embed') {
    return (
      <div className="media-embed__background">
        <div className="media-embed__width">
          <div className="media-embed__height">
            <iframe
              src={url}
              frameBorder="0"
              allowFullScreen
              title={id}></iframe>
          </div>
        </div>
      </div>
    );
  }
  return <span></span>;
};

Media.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  url: PropTypes.string,
  altText: PropTypes.string,
  x: PropTypes.string,
  y: PropTypes.string,
  useFullImg: PropTypes.bool,
};

Media.defaultProps = {
  x: '50%',
  y: '50%',
  textColor: 'light'
};

export default Media;
