import React from 'react';
import Button from '../../../../audi-ui-components/Button';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import { request } from '../../../../lib/apiRequestWrapper';
import { gtmPush } from '../../../../lib/gtm';

import { connect } from 'react-redux';
import { removeWaitlist } from '../../../../redux/Experience';
const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        removeWaitlist: (id, successCb, errCb) => dispatch(removeWaitlist(id, successCb, errCb))
    };
};

class SummaryWaitlist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            serverError: null
        }
    }

    cancelWaitlist = () => {
        const {id} = this.props;
        this.setState({isSubmitting: true});
        this.props.removeWaitlist(
            id,
            () => {
                gtmPush("AudiExperience", "eventCancelWaitlistSuccess", this.props.eventName);
            },
            (error) => {
                let msg = "An error has occured";
                if (error.body && error.body.message) {
                  msg = error.body.message;
                }
                this.setState({serverError: msg, isSubmitting: false});
            }
        );
    }

    render() {
        return (
            <div className="position-relative">
                {this.state.isSubmitting && <LoadingOverlay bgColour="gray95" />}
                <h2 className="aui-headline-3 mt-4 mb-3"><b>Waitlist</b></h2>
                <p className="mb-5">Thank you. You have been added to the waitlist for this event. If a space becomes available, we will SMS you with details to book your ticket.</p>
                {this.state.serverError && <p className="aui-color-text-red py-3">{this.state.serverError}</p>}
                <Button label="Cancel waitlist" buttonType="secondary" onClick={this.cancelWaitlist} />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryWaitlist);
